
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/4fee9142a293e2ef/members-area/my-account-ooi/src/components/MyAccount/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/yoshi-flow-editor/i18n';


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
}


  import stylesParams from '/home/builduser/agent00/work/4fee9142a293e2ef/members-area/my-account-ooi/src/components/MyAccount/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://4a635aca79034cd29cdc1f753ac27a34@sentry.wixpress.com/1234',
      id: '4a635aca79034cd29cdc1f753ac27a34',
      projectName: 'my-account-ooi',
      teamName: 'mm',
      
    };

  var translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/agent00/work/4fee9142a293e2ef/members-area/my-account-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '14dd1af6-3e02-63db-0ef2-72fbc7cc3136',
    name: 'MyAccount',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
